import * as React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import ReturnPage from './ReturnPage';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/actions';
import { selectors } from '../../store/selectors';
import { getInitData } from './MonitoringBox.utils';
import { sportFeatures } from '../../common/constants';

const MonitoringBox = ({ event_id, source }) => {
    const [loadingFlag, setLoadingFlag] = useState(false);
    const eventId = event_id + '_' + source;
    const sport = String(useSelector(selectors[eventId].selectSport));
    const sportId = typeof sportFeatures[sport] !== 'undefined' ? sportFeatures[sport].id : '';
    const isAuthorized = cloneDeep(useSelector(selectors['common'].selectAuthorized));
    const auth0Token = cloneDeep(useSelector(selectors['common'].selectAuth0Token));
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth0Token && isAuthorized && sportId !== '') {
            getInitData(event_id, source, sportId, setLoadingFlag);
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, [isAuthorized, auth0Token]);

    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(actions[eventId].setGameTime());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, []);

    return (
        /* Return different COMPONENTS */
        <ReturnPage
            event_id={event_id}
            loadingFlag={loadingFlag}
            source={source}
        />
    )
}

export default MonitoringBox;
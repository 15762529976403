import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectors } from '../../store/selectors';
import { actions } from '../../store/actions';
import cloneDeep from 'lodash.clonedeep';
import { useAuth0 } from '@auth0/auth0-react';

const AuthProvider = ({ children }) => {

    const config = cloneDeep(useSelector(selectors['common'].selectConfig));
    const dispatch = useDispatch();
    const [organizationId, setOrgId] = useState('');

    const { loginWithRedirect, /* error, */ isAuthenticated, isLoading, user,
    /* getAccessTokenSilently, getAccessTokenWithPopup, getIdTokenClaims, loginWithPopup, logout */ } = useAuth0();

    useEffect(() => {
        (async function login() {


            if (!isLoading && !user && !isAuthenticated) { // redirect to auth0 pop up and save the full url
                if (organizationId !== '') {
                    await loginWithRedirect({ organization: organizationId });
                    localStorage.setItem('url-feed-monitor', window.location.href);
                }
            }
            else {
                /* update store that user is autorized */
                dispatch(actions['common'].setAuthorized());
                /* update store with user who has logged in*/
                if (user && user.name) {
                    dispatch(actions['common'].setUser(user.name));
                }
            }

            /* little hack to prevent from infinity loop */
            if (isAuthenticated) {
                const getUrl = localStorage.getItem('url-feed-monitor');
                if (getUrl !== null) {
                    localStorage.removeItem('url-feed-monitor');
                    window.location.assign(getUrl); // take the full url after the success log in trought auth0
                }
            }

        })();
        //eslint-disable-next-line
    }, [isLoading, isAuthenticated, organizationId]);

    useEffect(() => {
        (async function getOrgId() {
            if (config['REACT_APP_ENV'] !== 'local') {
                const response = await fetch(config['AUTH0_ORGANIZATION_LAMBDA']);
                if (!response.ok) {
                    console.log('Failed to get organization id');
                }
                const organizationData = await response.json();
                setOrgId(organizationData.organizationId);
            } else {
                setOrgId(config['AUTH0_ORGANIZATION_ID']);
            }

            // if (organizationId) {
            dispatch(actions['common'].setOrganizationId(organizationId));
            //  }




        })();

        //eslint-disable-next-line
    }, []);


    return Object.keys(config).length > 0 && organizationId !== '' && children// return children if we have some props in config
};

export default AuthProvider;

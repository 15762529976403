import React, { useEffect } from 'react';
import { splitEventsFromURL, envByUrl } from '../../common/utils';
import MonitoringBox from './MonitoringBox';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store/actions';
import { useMonitoringToolStyles } from './MonitoringToolStyles';
import ErrorPage from './ErrorPage';
import { sportFeatures } from '../../common/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { selectors } from '../../store/selectors';
import cloneDeep from 'lodash.clonedeep';

const MonitoringTool = ({ test, testCount }) => {
    const dispatch = useDispatch();
    const classes = useMonitoringToolStyles();
    let events = splitEventsFromURL();

    if (test) { /* for testing purposes */
        events = splitEventsFromURL(testCount);
    }

    let monitoringBoxes = []; //Populate number of Grids(matches) will have opened in Feed Monitor
    const envName = envByUrl();
    const config = useSelector(selectors['common'].selectConfig);
    const organizationId = String(useSelector(selectors['common'].selectOrganizationId));
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const closedWidgets = useSelector(selectors['common'].selectClosedWidgets);

    if (events.length && !test) {
        events.forEach(element => {
            let sourceIds = sportFeatures[element.sport].sourceIds[envName];
            let tempClosedWidgets = cloneDeep(closedWidgets);

            if (config["REACT_APP_ENV"] === 'CI' && element.event_id.includes('testScenario')) {
                sourceIds = sourceIds.splice(0, 1);
            }

            sourceIds.forEach(source => {
                monitoringBoxes.push(
                    /* Populating all gridItems (Match boxes) */
                    <Grid
                        id={element.event_id + '_' + source}
                        data-cy={"grid-item-" + element.event_id + '_' + source}
                        key={element.event_id + '_' + source}
                        item
                        // xs={3}
                        className={classes.gridItem}
                        style={{
                            display: tempClosedWidgets && tempClosedWidgets.includes(element.event_id + '_' + source) ? 'none' : 'flex'
                        }}
                    >
                        <MonitoringBox
                            event_id={element.event_id}
                            source={source}
                        />
                    </Grid>
                );
            })
            //eslint-disable-next-line
        });
    }

    useEffect(() => {

        if (events.length && !test) {

            const getToken = async () => {
                try {
                    const token = await getAccessTokenSilently({
                        audience: config['AUTH0_AUDIENCE'],
                        scope: 'feed-monitor:admin',
                    });

                    dispatch(actions['common'].setAuth0Token(token));
                } catch (e) {
                    const err = cloneDeep(e);
                    if (err.error === 'login_required' || err.error === 'consent_required') {
                        await loginWithRedirect({ organization: organizationId });
                    }

                    console.log(err);
                }
            }

            getToken();

            events.forEach(element => {
                const sourceIds = sportFeatures[element.sport].sourceIds[envName];
                sourceIds.forEach(source => {
                    dispatch(actions[element.event_id + '_' + source].setSport(element.sport));
                });
                //eslint-disable-next-line
            });
        }

        // empty dependency array means this effect will only run once (like componentDidMount in classes)
        // eslint-disable-next-line
    }, []);
    if ((events.length && events[0].event_id !== 'testUnit' && !test) || (test && events.length && events[0].event_id === 'testUnit')) {
        if (events.length > 8) {
            return (
                <ErrorPage value={"You have selected more than 8 matches!"} />
            )
        }
        else {
            return (
                <div className={classes.mt4Wrapper}>
                    {/* Return Grid container containing Grid items populated from monitoringBoxes */}
                    <Grid
                        data-cy="grid-container"
                        data-testid="grid-container"
                        container
                        spacing={1}
                        className={classes.gridContainer}
                    >
                        {monitoringBoxes}
                    </Grid>
                </div>
            );
        }

    } else {
        return (
            <ErrorPage value={"Missing/Wrong url parameters"} />
        )
    }

}

export default MonitoringTool;
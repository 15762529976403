import { EventsInterface } from "../types";
import { allowedSport, downMapping, madeMissedCommands, yardsContainingCommands, propsToCompare } from "./constants";
import { cloneDeep } from "lodash";

export const isValidMatch = (element) => {
    if (element !== "") {
        return true;
    }
    return false;
};

export const splitEventsFromURL = (testCount?: number): EventsInterface[] => {
    let result = [];
    const fixtures = new URLSearchParams(window.location.search).get(
        "fixtures",
    );

    if (fixtures !== null) {
        const eventsArray = fixtures.split(",");
        let allEventIds = [];

        eventsArray.forEach((element) => {
            if (isValidMatch(element)) {
                let eventData = element.split("_");
                /* Need to add sanity check for ID */
                if (
                    allowedSport.includes(eventData[0]) &&
                    !allEventIds.includes(eventData[1])
                ) {
                    /*  */
                    allEventIds.push(eventData[1]);
                    result.push({
                        sport: eventData[0],
                        event_id: eventData[1],
                    });
                }
            }
        });
    } else {
        /* to simulate more than 8 matches */
        if (testCount && testCount > 0) {
            result.push(
                { sport: "americanfootball", event_id: "testUnit" },
                { sport: "americanfootball", event_id: "test2" },
                { sport: "americanfootball", event_id: "test3" },
                { sport: "americanfootball", event_id: "test4" },
                { sport: "americanfootball", event_id: "test5" },
                { sport: "americanfootball", event_id: "test6" },
                { sport: "americanfootball", event_id: "test7" },
                { sport: "americanfootball", event_id: "test8" },
                { sport: "americanfootball", event_id: "test9" },
            );
        } else {
            /* this is for unit testing purposes */
            result.push(
                { sport: "americanfootball", event_id: "testUnit" },
                { sport: "americanfootball", event_id: "unitTest" },
            );
        }
    }

    return result;
};

/* Update the correct Button Name */
export const closeOrOpenGame = (type) => {
    let temp_type = type;
    switch (type) {
        case "openGame":
            temp_type = "openGame";
            break;
        case "closeGame":
            temp_type = "closeGame";
            break;

        default:
            temp_type = "";
            break;
    }
    return (
        temp_type.substring(0, temp_type.length - 4) +
        " " +
        temp_type.substring(temp_type.length - 4, temp_type.length)
    );
};

export const fetchData = async (url: string) => {
    try {
        const data = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return await data.json();
    } catch (e) {
        return null;
    }
};

export const envByUrl = () => {
    const hostName = window.location.host;
    let envName = "prod";

    if (hostName.includes("ci.") || hostName.includes("localhost")) {
        envName = "ci";
    } else if (hostName.includes("uat.")) {
        envName = "uat";
    }

    return envName;
};

export const setClockValue = (gameTime: number): string => {
    const min = Math.floor(gameTime / 60);
    let sec = String(gameTime % 60);
    if (sec.length === 1) {
        sec = "0" + sec;
    }

    return min + ":" + sec;
};

export const getObjectParamCamelCase = (object, key) => {
    if (
        typeof object !== "undefined" &&
        object !== null &&
        typeof object === "object"
    ) {
        return object[
            Object.keys(object).find(
                (k) => k.charAt(0).toLowerCase() + k.slice(1) === key,
            )
        ];
    } else {
        return false;
    }
};

/* Take command keys from MSF contract events */
export const commandKeyExtractor = (keyContract) => {
    const resultArr = keyContract.split(".");

    const result = resultArr[resultArr.length - 1];

    return result;
};

/* Take correct value for specific commands from MSF event */
export const generatedValue = (tempAction, type, key) => {
    const propArr = Object.keys(type);

    let text = "";

    if (propArr.length) {
        propArr.forEach((prop) => {
            const arrType = type[prop];

            if (arrType.length) {
                arrType.forEach((element) => {
                    const action = getObjectParamCamelCase(tempAction, prop);
                    text +=
                        action &&
                        getObjectParamCamelCase(action, element) !== null
                            ? getObjectParamCamelCase(action, element) + " "
                            : "";
                });
            } else {
                //special case only for down command in american football
                if (key === "Down" && prop === "number") {
                    text +=
                        getObjectParamCamelCase(tempAction, prop) &&
                        getObjectParamCamelCase(tempAction, prop) !== null
                            ? downMapping[
                                  getObjectParamCamelCase(tempAction, prop) - 1
                              ] + "&"
                            : "";
                } else if (madeMissedCommands.includes(key)) {
                    if (getObjectParamCamelCase(tempAction, prop) !== null) {
                        if (getObjectParamCamelCase(tempAction, prop)) {
                            text += 'Made';
                        } else {
                            text += 'Missed';
                        }
                    }
                } else if (yardsContainingCommands.includes(key) && prop === 'yards') {
                    text +=
                        getObjectParamCamelCase(tempAction, prop) &&
                        getObjectParamCamelCase(tempAction, prop) !== null
                            ? getObjectParamCamelCase(tempAction, prop) + 'YD'
                            : "";
                } else {
                    text +=
                        getObjectParamCamelCase(tempAction, prop) &&
                        getObjectParamCamelCase(tempAction, prop) !== null
                            ? getObjectParamCamelCase(tempAction, prop) + " "
                            : "";
                }
            }
        });
    }

    return text;
};

/* Take the filtered commands from local storage*/
export const getFilteredCommands = (key) => {
    let filteredCommands = [];

    if (typeof localStorage.getItem(key) !== 'undefined' && localStorage.getItem(key) !== null) {
        filteredCommands = JSON.parse(localStorage.getItem(key));
    }

    return filteredCommands;
};

/* Generate notification text*/
export const getNotificationTextForEdit = (actionId, notification, key, timestamp) => {
    let modificationText = '';
    let modificationTextArr = [];
    let text = '';

    const obj1 = getObjectParamCamelCase(notification, 'action');
    const objectHistory = cloneDeep(notification.history);
    const objectHistoryLength = objectHistory.length;

    if (objectHistoryLength) {
        const lastState = cloneDeep(objectHistory[objectHistoryLength - 1]);
        const obj2 = getObjectParamCamelCase(lastState, 'action');

        propsToCompare.forEach((prop) => {
            const result = getComparedText(prop, obj1, obj2);
            if ( result !== '') {
                modificationTextArr.push(result);
            }
        });
        
    }

    if (modificationTextArr.length) {
        modificationText = modificationTextArr.join(' | ');
        text = '#' + actionId + ' ' + key + ' was modified -' + modificationText + ' at ' + timestamp;
    }
    
    return text;

}

/* Compares properties of object and return string*/
export const getComparedText = (property, obj1, obj2) => {
    let result = "";

    if (property !== 'players') {
        if (obj1[property] && obj2[property] && obj1[property] !== obj2[property]) {
            let oldValue = obj2[property];
            let newValue = obj1[property];

            if (property === 'gameTime') {
                oldValue = oldValue.replace("00:", "");
                newValue = newValue.replace("00:", "");

                const oldGametime = oldValue.split(':');
                const newGametime = newValue.split(':');

                const oldGametimeValue = parseInt(oldGametime[0])*60 + parseInt(oldGametime[1]);
                const newGametimeValue = parseInt(newGametime[0])*60 + parseInt(newGametime[1]);
                
                //add notification when the diff is 30 or more seconds
                if (Math.abs(oldGametimeValue - newGametimeValue) >= 30) {
                    result = ' "' + property + '" from "' + oldValue + '" to "' + newValue + '"';    
                }
            } else if (property === 'yardsToGo') {
                //add notification when the diff is 5 or more yards
                if (Math.abs(parseInt(oldValue) - parseInt(newValue)) >= 5) {
                    result = ' "' + property + '" from "' + oldValue + '" to "' + newValue + '"';    
                }
            } else {
                result = ' "' + property + '" from "' + oldValue + '" to "' + newValue + '"';
            }
        }
    } else {
        //if old players value is not present/empty and new players value is present/not empty
        if ((typeof obj2[property] !== 'undefined' && (obj2[property] === null || (obj2[property] !== null && obj2[property].length === 0))) && 
            (typeof obj1[property] !== 'undefined'&& obj1[property] !== null && obj1[property].length > 0)
        ) {
            let oldValue = 'empty';
            let newValue = JSON.stringify(obj1[property]);

            result = ' "' + property + '" from "' + oldValue + '" to "' + newValue + '"';
        } 
        //if old players value is present/not empty and new players value is not present/empty
        else if ((typeof obj1[property] !== 'undefined' && (obj1[property] === null || (obj1[property] !== null && obj1[property].length === 0))) && 
                 (typeof obj2[property] !== 'undefined' && obj2[property] !== null && obj2[property].length > 0)
        ) {
            let oldValue = JSON.stringify(obj2[property]);
            let newValue = 'empty';

            result = ' "' + property + '" from "' + oldValue + '" to "' + newValue + '"';
        }
        /*if old players value is present/not empty and new players value is present/ not empty 
         but the number of players is different or the number is equal but the players content is different*/
        else if ((typeof obj1[property] !== 'undefined' && typeof obj2[property] !== 'undefined') && 
                 (obj1[property] !== null && obj1[property].length > 0) && 
                 (obj2[property] !== null && obj2[property].length > 0) && 
                 ((obj1[property].length !== obj2[property].length) || 
                  (obj1[property].length === obj2[property].length && (JSON.stringify(obj1[property]) !== JSON.stringify(obj2[property])))
                 )
        ) {
            let oldValue = JSON.stringify(obj2[property]);
            let newValue = JSON.stringify(obj1[property]);

            result = ' "' + property + '" from "' + oldValue + '" to "' + newValue + '"';
        }
    }

    return result;
};
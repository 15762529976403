import { AnyAction, combineReducers } from "redux";
import { RootState, CommonState } from "./types";
import { actionTypes } from "./actions";
import { splitEventsFromURL, envByUrl } from "../common/utils";
import { sportFeatures } from "../common/constants";
import { cloneDeep } from "lodash";

export const initialState: RootState = {
    gameTime: 0,
    isClockRunning: false,
    currentCloseOpenGame: "closeGame",
    disabledCloseOpenGame: false,
    commandLog: [],
    sport: "",
    fixtureData: {},
    matchState: {},
    ablyClient: {},
    ablyClientME: {},
    sequenceId: 0,
    liveAccessForCloseGame: false,
    mappingIds: [],
    notifications: [],
    notificationsCounter: 0,
    checkedNotifications: [],
    tabFlag: false,
    risks: {},
    activeRisks: [],
    listPosition: 0
};

export const initialCommonState: CommonState = {
    user: "",
    authorized: false,
    config: {},
    auth0Token: "",
    organizationId: "",
    closedWidgets: [],
};

export const reducersObj = {};

const eventsArray = splitEventsFromURL();

eventsArray.forEach((element) => {
    const envName = envByUrl();
    const sourceIds = sportFeatures[element.sport].sourceIds[envName];

    sourceIds.forEach((source) => {
        const eventId = element.event_id + "_" + source;
        reducersObj[eventId] = (
            state = initialState,
            action: AnyAction,
        ): RootState => {
            switch (action.type) {
                case actionTypes[eventId].setGameTime: {
                    return {
                        ...state,
                        gameTime:
                            action.payload > 1
                                ? action.payload
                                : state.isClockRunning && state.gameTime > 0
                                    ? state.gameTime - action.payload
                                    : state.gameTime,
                    };
                }
                case actionTypes[eventId].stopClock: {
                    return {
                        ...state,
                        isClockRunning: action.payload,
                    };
                }
                case actionTypes[eventId].startClock: {
                    return {
                        ...state,
                        isClockRunning: action.payload,
                    };
                }
                case actionTypes[eventId].resetClock: {
                    return {
                        ...state,
                        gameTime: action.payload,
                    };
                }
                case actionTypes[eventId].setCloseOpenGame: {
                    return {
                        ...state,
                        currentCloseOpenGame: action.payload,
                    };
                }
                case actionTypes[eventId].setDisabledCloseOpenGame: {
                    return {
                        ...state,
                        disabledCloseOpenGame: action.payload,
                    };
                }
                case actionTypes[eventId].setCommandLog: {
                    let mappingIds = cloneDeep(state.mappingIds);
                    let tempLog = cloneDeep(state.commandLog);
                    let tempIndex = Number(state.listPosition) + (state.listPosition > 0 ? 1 : 0);

                    tempLog.unshift(action.payload);

                    if (action.payload.actionId) {
                        if (!mappingIds[action.payload.actionId]) {
                            mappingIds[action.payload.actionId] = [];
                        }
                        mappingIds[action.payload.actionId].push(action.payload.sequenceId);

                        const length = mappingIds[action.payload.actionId].length;
                        if (length > 1) {
                            tempLog[0].history = [];
                            for (let index = 0; index < length - 1; index++) {
                                const key = tempLog.length - mappingIds[action.payload.actionId][index];
                                tempLog[key].hidden = true;
                                delete tempLog[key].history;
                                tempLog[0].history && tempLog[0].history.push(tempLog[key]);
                            }
                        }
                    }

                    return {
                        ...state,
                        commandLog: tempLog,
                        mappingIds: mappingIds,
                        listPosition: tempIndex
                    };
                }
                case actionTypes[eventId].setInitialCommandLog: {
                    let currentCommandLog = cloneDeep(state.commandLog);
                    let tempLog = cloneDeep(action.payload);
                    const length = currentCommandLog.length;

                    if (length) {
                        for (let index = length - 1; index >= 0; index--) {
                            if (currentCommandLog[index].sequenceId > tempLog[0].sequenceId) {
                                tempLog.unshift(currentCommandLog[index]);
                            }
                        }
                    }

                    return {
                        ...state,
                        commandLog: tempLog,
                    };
                }
                case actionTypes[eventId].setResetCommandLog: {
                    return {
                        ...state,
                        commandLog: action.payload,
                    };
                }
                case actionTypes[eventId].setExpanded: {
                    let tempLog = cloneDeep(state.commandLog);
                    const length = tempLog.length;
                    if (typeof tempLog[length - action.payload].expanded === 'undefined') {
                        tempLog[length - action.payload].expanded = true;
                    } else {
                        delete tempLog[length - action.payload].expanded;
                    }
                    return {
                        ...state,
                        commandLog: tempLog,
                    };
                }
                case actionTypes[eventId].setSport: {
                    return {
                        ...state,
                        sport: action.payload,
                    };
                }
                case actionTypes[eventId].setFixtureData: {
                    return {
                        ...state,
                        fixtureData: action.payload,
                    };
                }

                case actionTypes[eventId].setMatchState: {
                    return {
                        ...state,
                        matchState: action.payload,
                    };
                }

                case actionTypes[eventId].setAblyClient: {
                    return {
                        ...state,
                        ablyClient: action.payload,
                    };
                }

                case actionTypes[eventId].setAblyClientME: {
                    return {
                        ...state,
                        ablyClientME: action.payload,
                    };
                }

                case actionTypes[eventId].setSequenceId: {
                    return {
                        ...state,
                        sequenceId: action.payload,
                    };
                }

                case actionTypes[eventId].setLiveAccessForCloseGame: {
                    return {
                        ...state,
                        liveAccessForCloseGame: action.payload,
                    };
                }

                case actionTypes[eventId].setMappingIds: {
                    return {
                        ...state,
                        mappingIds: action.payload,
                    };
                }

                case actionTypes[eventId].setNotifications: {
                    return {
                        ...state,
                        notifications: action.payload,
                    };
                }

                case actionTypes[eventId].setNotificationsCounter: {
                    return {
                        ...state,
                        notificationsCounter: action.payload,
                    };
                }

                case actionTypes[eventId].setCheckedNotifications: {
                    return {
                        ...state,
                        checkedNotifications: action.payload,
                    };
                }

                case actionTypes[eventId].setRisks: {
                    return {
                        ...state,
                        risks: action.payload,
                    };
                }

                case actionTypes[eventId].setActiveRisks: {
                    return {
                        ...state,
                        activeRisks: action.payload,
                    };
                }

                case actionTypes[eventId].setListPosition: {
                    return {
                        ...state,
                        listPosition: action.payload,
                    };
                }

                /* ====================================== */
                /* American Football - sport specific  */

                default:
                    return state;
            }
        };
    });
});

export const commonReducer = (
    state = initialCommonState,
    action: AnyAction,
): CommonState => {
    switch (action.type) {
        case actionTypes["common"].setUser: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case actionTypes["common"].setAuthorized: {
            return {
                ...state,
                authorized: true,
            };
        }
        case actionTypes["common"].setConfig: {
            return {
                ...state,
                config: action.payload,
            };
        }
        case actionTypes["common"].setAuth0Token: {
            return {
                ...state,
                auth0Token: action.payload,
            };
        }
        case actionTypes["common"].setOrganizationId: {
            return {
                ...state,
                organizationId: action.payload,
            };
        }
        case actionTypes["common"].setClosedWidgets: {
            return {
                ...state,
                closedWidgets: action.payload,
            };
        }
        default:
            return state;
    }
};

export const rootReducer = combineReducers({
    ...reducersObj,
    common: commonReducer,
});

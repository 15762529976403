import { AnyAction } from "redux";
import cloneDeep from "lodash.clonedeep";
import { splitEventsFromURL, envByUrl } from "../common/utils";
import { sportFeatures } from "../common/constants";

const actionsObj = {};
const actionsTypesObj = {};

const eventsArray = splitEventsFromURL();

eventsArray.forEach((element) => {
    const envName = envByUrl();
    const sourceIds = sportFeatures[element.sport].sourceIds[envName];

    sourceIds.forEach((source) => {
        const eventId = element.event_id + "_" + source;
        actionsTypesObj[eventId] = {
            setGameTime: "setGameTime_" + eventId,
            stopClock: "stopClock_" + eventId,
            startClock: "startClock_" + eventId,
            resetClock: "resetClock_" + eventId,
            setCloseOpenGame: "setCloseOpenGame_" + eventId,
            setDisabledCloseOpenGame: "setDisabledCloseOpenGame_" + eventId,
            setCommandLog: "setCommandLog_" + eventId,
            setInitialCommandLog: "setInitialCommandLog_" + eventId,
            setResetCommandLog: "setResetCommandLog_" + eventId,
            setExpanded: "setExpanded_" + eventId,
            setSport: "setSport_" + eventId,
            setFixtureData: "setFixtureData_" + eventId,
            setMatchState: "setMatchState_" + eventId,
            setAblyClient: "setAblyClient_" + eventId,
            setAblyClientME: "setAblyClientME_" + eventId,
            setSequenceId: "setSequenceId_" + eventId,
            setLiveAccessForCloseGame: "setLiveAccessForCloseGame_" + eventId,
            setMappingIds: "setMappingIds_" + eventId,
            setNotifications: "setNotifications_" + eventId,
            setNotificationsCounter: "setNotificationsCounter_" + eventId,
            setCheckedNotifications: "setCheckedNotifications_" + eventId,
            setTabFlag: "setTabFlag_" + eventId,
            setRisks: "setRisks_" + eventId,
            setActiveRisks: "setActiveRisks_" + eventId,
            setListPosition: "setListPosition_" + eventId,
        };

        actionsObj[eventId] = {
            setGameTime: (gameTime?: number): AnyAction => ({
                type: actionsTypesObj[eventId].setGameTime,
                payload: gameTime ? gameTime : 1,
            }),

            stopClock: (): AnyAction => ({
                type: actionsTypesObj[eventId].stopClock,
                payload: false,
            }),

            startClock: (): AnyAction => ({
                type: actionsTypesObj[eventId].startClock,
                payload: true,
            }),

            resetClock: (): AnyAction => ({
                type: actionsTypesObj[eventId].resetClock,
                payload: 0,
            }),

            setCommandLog: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setCommandLog,
                payload: value,
            }),

            setInitialCommandLog: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setInitialCommandLog,
                payload: value,
            }),

            setResetCommandLog: (): AnyAction => ({
                type: actionsTypesObj[eventId].setResetCommandLog,
                payload: [],
            }),

            setExpanded: (value: number): AnyAction => ({
                type: actionsTypesObj[eventId].setExpanded,
                payload: value,
            }),

            setSport: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setSport,
                payload: value,
            }),

            setFixtureData: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setFixtureData,
                payload: value,
            }),

            setMatchState: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setMatchState,
                payload: value,
            }),

            setCloseOpenGame: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setCloseOpenGame,
                payload: value,
            }),

            setDisabledCloseOpenGame: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setDisabledCloseOpenGame,
                payload: value,
            }),

            setAblyClient: (value: object): AnyAction => ({
                type: actionsTypesObj[eventId].setAblyClient,
                payload: value,
            }),

            setAblyClientME: (value: object): AnyAction => ({
                type: actionsTypesObj[eventId].setAblyClientME,
                payload: value,
            }),

            setSequenceId: (value: number): AnyAction => ({
                type: actionsTypesObj[eventId].setSequenceId,
                payload: value,
            }),

            setLiveAccessForCloseGame: (value: string): AnyAction => ({
                type: actionsTypesObj[eventId].setLiveAccessForCloseGame,
                payload: value,
            }),

            setMappingIds: (value: Array<Number>): AnyAction => ({
                type: actionsTypesObj[eventId].setMappingIds,
                payload: value,
            }),

            setNotifications: (value: Array<Number>): AnyAction => ({
                type: actionsTypesObj[eventId].setNotifications,
                payload: value,
            }),

            setNotificationsCounter: (value: Array<Number>): AnyAction => ({
                type: actionsTypesObj[eventId].setNotificationsCounter,
                payload: value,
            }),

            setCheckedNotifications: (value: Array<Number>): AnyAction => ({
                type: actionsTypesObj[eventId].setCheckedNotifications,
                payload: value,
            }),

            setTabFlag: (value: Array<Number>): AnyAction => ({
                type: actionsTypesObj[eventId].setTabFlag,
                payload: value,
            }),

            setRisks: (value: object): AnyAction => ({
                type: actionsTypesObj[eventId].setRisks,
                payload: value,
            }),

            setActiveRisks: (value: Array<string>): AnyAction => ({
                type: actionsTypesObj[eventId].setActiveRisks,
                payload: value,
            }),

            setListPosition: (value: Array<string>): AnyAction => ({
                type: actionsTypesObj[eventId].setListPosition,
                payload: value,
            }),

            /* ====================================== */
            /* American Football - sport specific  */
        };
    });
});

actionsTypesObj["common"] = {
    setUser: "setUser",
    setAuthorized: "setAuthorized",
    setConfig: "setConfig",
    setAuth0Token: "setAuth0Token",
    setOrganizationId: "setOrganizationId",
    setClosedWidgets: "setClosedWidgets",
};

actionsObj["common"] = {
    setUser: (value: string): AnyAction => ({
        type: actionsTypesObj["common"].setUser,
        payload: value,
    }),
    setAuthorized: (): AnyAction => ({
        type: actionsTypesObj["common"].setAuthorized,
    }),
    setConfig: (value: string) => ({
        type: actionsTypesObj["common"].setConfig,
        payload: value,
    }),
    setAuth0Token: (value: string) => ({
        type: actionsTypesObj["common"].setAuth0Token,
        payload: value,
    }),
    setOrganizationId: (value: string) => ({
        type: actionsTypesObj["common"].setOrganizationId,
        payload: value,
    }),
    setClosedWidgets: (value: string) => ({
        type: actionsTypesObj["common"].setClosedWidgets,
        payload: value,
    }),
};

export const actions = cloneDeep(actionsObj);
export const actionTypes = cloneDeep(actionsTypesObj);

import React, { Suspense } from 'react';
import LoadingScreen from './LoadingScreen';
import { sportsMapping } from '../../common/constants'
import { useSelector } from 'react-redux';
import { selectors } from '../../store/selectors';
import ErrorPage from './ErrorPage';



const ReturnPage = ({ loadingFlag, event_id, source }) => {
    const eventId = event_id + '_' + source;
    const sport = String(useSelector(selectors[eventId].selectSport));
    const fixtureData = useSelector(selectors[eventId].selectFixtureData);
    
    if (JSON.stringify(fixtureData) !== '{}' && sport !== '') {
        if (loadingFlag) {
            const sportCompData = sportsMapping[sport];
            /* RETURN Box only when commands are loaded!!!  */
            return ( /* Load full HTML Sceleton */
                <Suspense fallback={<LoadingScreen />}>
                    <sportCompData.component
                        event_id={event_id}
                        source={source}
                    />
                </Suspense>
            );
        }
        /* Show loading screen for the gap */
        else {
            return (
                <LoadingScreen />
            )
        }
    } else if (JSON.stringify(fixtureData) === '{}') {
        return ( /* Load loading screen */
            <LoadingScreen />
        );
    } /*else if (event_info === false) {
        return (
            <div>
                <ErrorPage value={'No Event Info Data for event ' + { event_id }} />
            </div>
        );
    }*/ else {
        return (
            <ErrorPage value={"Missing/Wrong url parameters"} /> /*  think this is correct component which should be rendered here! */
            // <ErrorPage value={message.value} />
        );
    }
}

export default ReturnPage;
import { actions } from "../../store/actions";
import { store } from "../../store";
import * as Ably from "ably";
import Moment from 'moment';
import {
    getObjectParamCamelCase,
    commandKeyExtractor,
    getFilteredCommands,
    getNotificationTextForEdit,
} from "../../common/utils";
import { cloneDeep } from "lodash";
import { notificationCommands, sportFeatures } from '../../common/constants';

export const getInitData = async (
    event_id,
    source,
    sportId,
    setLoadingFlag,
) => {
    const config = store.getState()["common"].config;
    const user = store.getState()["common"].user;

    const params = JSON.stringify({
        sportId: sportId,
        event_id: event_id,
        sourceId: source,
        user: user,
    });

    const isTestScenario = event_id.includes('testScenario');

    try {
        const token = store.getState()["common"].auth0Token;
        const endPoint = isTestScenario ? 'initAutomationTest' : 'init';

        const feedMonitorControllerUrl =
            config["FEED_MONITOR_CONTROLLER_URL"] + endPoint;
        const response = await fetch(feedMonitorControllerUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: params,
        });

        if (response.status === 200) {
            const initData = await response.json();
            console.log(initData);
            if (initData.fixtureData) {
                store.dispatch(
                    actions[event_id + "_" + source].setFixtureData(
                        initData.fixtureData,
                    ),
                );
                setLoadingFlag(true);
            }

            if (initData.matchState) {
                populateMatchState(event_id, source, initData.matchState);
            }

            if (initData.liveAccess) {
                subscribeToAbly(
                    initData.liveAccess.accessToken,
                    initData.liveAccess.channelName,
                    "liveaccess",
                    event_id,
                    source,
                    sportId,
                );
            }

            if (initData.liveAccessME) {
                subscribeToAbly(
                    initData.liveAccessME.accessToken,
                    initData.liveAccessME.channelName,
                    "matchevents",
                    event_id,
                    source,
                    sportId,
                );
            }

            /* Load match events data for automation fixtures */
            /* Automation only on CI environment */
            if (isTestScenario && config["REACT_APP_ENV"] === 'CI') {
                populateMatchFeedEvents(
                    event_id,
                    source,
                    initData.matchEvents,
                    true,
                    sportId,
                );
            }

            if (event_id !== 'testUnit' && !isTestScenario) {
                getMatchEvents(event_id, source, sportId);
            }
        } else if (response.status === 404) {
            const element = document.getElementById(event_id + "_" + source);
            element?.parentNode.removeChild(element);
        }
    } catch (e) {
        console.error(e);
    }
};

export const subscribeToAbly = (
    token,
    channelName,
    type,
    event_id,
    source,
    sportId,
) => {
    if (event_id !== 'testUnit') {
        const options: Ably.Types.ClientOptions = {
            token: token,
            environment: "geniussports",
            fallbackHosts: [
                "geniussports-a-fallback.ably-realtime.com",
                "geniussports-b-fallback.ably-realtime.com",
                "geniussports-c-fallback.ably-realtime.com",
                "geniussports-d-fallback.ably-realtime.com",
                "geniussports-e-fallback.ably-realtime.com",
            ],
            authCallback: () => {
                authCallbackAbly(type, event_id, source, sportId);
            },
        };

        const client = new Ably.Realtime(options); /* inferred type Ably.Realtime */

        if (type === "liveaccess") {
            store.dispatch(actions[event_id + "_" + source].setAblyClient(client));
            /* enable close/open game button when there is live access*/
            store.dispatch(actions[event_id + "_" + source].setLiveAccessForCloseGame(true));
        } else {
            store.dispatch(
                actions[event_id + "_" + source].setAblyClientME(client),
            );
        }

        client.connection.on(function (stateChange) {
            console.log("New connection state is " + stateChange.current);
        });

        const channel =
            client.channels.get(
                channelName,
            ); /*inferred type Ably.Types.RealtimeChannel*/

        channel.subscribe((message) => {
            if (type === "matchevents") {
                console.log(message.data);
                if (message.data) {
                    populateMatchFeedEvents(
                        event_id,
                        source,
                        message.data,
                        false,
                        sportId,
                    );
                }
            } else {
                console.log(message);
                if (message.data) {
                    populateMatchState(event_id, source, message.data);
                }
            }
        });
    }
};

export const populateMatchState = (event_id, source, matchState) => {
    const eventId = event_id + "_" + source;
    store.dispatch(actions[eventId].setMatchState(matchState));
    store.dispatch(actions[eventId].setDisabledCloseOpenGame(false));

    // TO DO make function to rewrite object property first letter
    if (matchState.reliabilityReasons) {
        if (
            typeof getObjectParamCamelCase(
                matchState.reliabilityReasons,
                "feedReliability",
            ) !== "undefined" &&
            getObjectParamCamelCase(
                matchState.reliabilityReasons,
                "feedReliability",
            ) !== null &&
            getObjectParamCamelCase(
                matchState.reliabilityReasons,
                "feedReliability",
            ).includes("Close Game")
        ) {
            store.dispatch(actions[eventId].setCloseOpenGame("openGame"));
        } else {
            store.dispatch(actions[eventId].setCloseOpenGame("closeGame"));
        }
    }

    const gameTimeArr = matchState.gameTime.clock.split(":");
    const gameTime = parseInt(gameTimeArr[1]) * 60 + parseInt(gameTimeArr[2]);

    if (!matchState.gameTime.isRunning) {
        store.dispatch(actions[eventId].stopClock());
        store.dispatch(actions[eventId].setGameTime(gameTime));
    } else {
        const currentIsClockRunning = store.getState()[eventId].isClockRunning;
        if (!currentIsClockRunning) {
            const lastUpdated = new Date(
                matchState.gameTime.lastUpdatedUtc,
            ).getTime();
            const now = new Date().getTime();
            store.dispatch(
                actions[eventId].setGameTime(
                    gameTime - Math.floor((now - lastUpdated > 0 ? now - lastUpdated : 0) / 1000),
                ),
            );
            store.dispatch(actions[eventId].startClock());
        }
    }
};

export const getMatchEvents = async (event_id, source, sportId) => {
    const config = store.getState()["common"].config;
    const user = store.getState()["common"].user;

    const params = JSON.stringify({
        sportId: sportId,
        event_id: event_id,
        sourceId: source,
        user: user,
    });

    try {
        const token = store.getState()["common"].auth0Token;

        const feedMonitorControllerUrl =
            config["FEED_MONITOR_CONTROLLER_URL"] + "matchevents";
        const response = await fetch(feedMonitorControllerUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: params,
        });

        if (response.status === 200) {
            const matchEventsData = await response.json();
            console.log(matchEventsData);
            if (matchEventsData.matchEvents) {
                populateMatchFeedEvents(
                    event_id,
                    source,
                    matchEventsData.matchEvents,
                    true,
                    sportId,
                );
            }
        }
    } catch (e) {
        console.error(e);
    }
};

export const populateMatchFeedEvents = (
    event_id,
    source,
    matchEvents,
    initialLoading,
    sportId,
) => {
    const eventId = event_id + "_" + source;
    let lastSequenceId = store.getState()[eventId].sequenceId;
    const filteredCommands = getFilteredCommands('filtered-commands-' + eventId);
    const sportStr = store.getState()[eventId].sport;
    let tempNotifications = cloneDeep(store.getState()[eventId].notifications);
    let tempNotificationsCounter = store.getState()[eventId].notificationsCounter;
    let tempRisks = cloneDeep(store.getState()[eventId].risks);
    let tempActiveRisks = cloneDeep(store.getState()[eventId].activeRisks);

    //if it is an initial loading we make new store item with the matchevents
    if (initialLoading) {
        //sort matchevents by sequenceId
        matchEvents.sort((a, b) => (a.sequenceId > b.sequenceId) ? 1 : -1);
        const sequenceId = matchEvents.length;

        if (lastSequenceId < sequenceId) {
            store.dispatch(actions[eventId].setSequenceId(sequenceId));
        }

        let tempMappingIds = [];
        const matchEventsLength = matchEvents.length;

        const checkedNotifications = getFilteredCommands('checked-notifications-' + eventId);
        const checkedNotificationsLength = checkedNotifications.length;

        if (matchEventsLength) {
            for (let i = 0; i < matchEventsLength; i++) {
                matchEvents[i].sequenceId = i + 1;

                const tempAction = getObjectParamCamelCase(matchEvents[i], "action");

                if (tempAction) {
                    const keyContract = tempAction["$type"].split(",")[0];
                    const key = commandKeyExtractor(keyContract);

                    if ( key === 'Risks'){
                      tempRisks[matchEvents[i].actionId] = defineRisks(sportStr, tempAction, tempActiveRisks);
                      tempActiveRisks = tempRisks[matchEvents[i].actionId].activeRisks;
                    }

                    if (matchEvents[i].actionId) {
                        if (!tempMappingIds[matchEvents[i].actionId]){
                            tempMappingIds[matchEvents[i].actionId] = [];
                        }

                        if (tempMappingIds[matchEvents[i].actionId].length){
                            const tempSequenceId = tempMappingIds[matchEvents[i].actionId][tempMappingIds[matchEvents[i].actionId].length - 1] - 1;
                            matchEvents[tempSequenceId].hidden = true;
                            if (matchEvents[tempSequenceId].history){
                                matchEvents[i].history = cloneDeep(matchEvents[tempSequenceId].history);
                                delete matchEvents[tempSequenceId].history;
                            } else {
                                matchEvents[i].history = [];
                            }
                            matchEvents[i].history.push(matchEvents[tempSequenceId]);
                        }
                        tempMappingIds[matchEvents[i].actionId].push(matchEvents[i].sequenceId);
                    }

                    if (filteredCommands.includes(key)) {
                        matchEvents[i].hidden = true;
                    }

                    if (notificationCommands[sportStr].includes(key)) {
                        const isCancelled = getObjectParamCamelCase(matchEvents[i], 'isCancelled');
                        const isModified = getObjectParamCamelCase(matchEvents[i], 'isModified');
                        const isNullified = getObjectParamCamelCase(tempAction, 'isNullified') ? ' Nullified' : '';
                        const actionId = getObjectParamCamelCase(matchEvents[i], 'actionId');
                        const notificationSequenceId = getObjectParamCamelCase(matchEvents[i], 'sequenceId');

                        if (isModified && !(isCancelled || isNullified !== '')) {
                            const timeStampFull = getObjectParamCamelCase(tempAction, 'utcTimestamp');
                            const timeStamp = Moment(new Date(timeStampFull)).utc().format("HH:mm:ss");

                            let modificationTextNotification = getNotificationTextForEdit(actionId, matchEvents[i], key, timeStamp);

                            if (modificationTextNotification !== '') {
                                tempNotifications.unshift({actionId: actionId, sequenceId: notificationSequenceId, notificationText: modificationTextNotification, cmdObj: cloneDeep(matchEvents[i]), type:"modified"});
                                tempNotificationsCounter++;
                            }

                        }

                        if (isCancelled || isNullified !== '') {
                            const timeStampFullDeleted = getObjectParamCamelCase(matchEvents[i], 'messageTimestampUtc');
                            const timeStampDeleted = Moment(new Date(timeStampFullDeleted)).utc().format("HH:mm:ss");

                            const deletionTextNotification = '#' + actionId + ' ' + key + ' was deleted at ' + timeStampDeleted;

                            tempNotifications.unshift({actionId: actionId, sequenceId: notificationSequenceId, notificationText: deletionTextNotification, cmdObj: cloneDeep(matchEvents[i]), type:"deleted"});
                            tempNotificationsCounter++;
                        }
                    }
                }
            }

            tempNotificationsCounter -= checkedNotificationsLength;
        }

        store.dispatch(
            actions[eventId].setMappingIds(tempMappingIds),
        );

        store.dispatch(
            actions[eventId].setNotifications(tempNotifications),
        );

        store.dispatch(
            actions[eventId].setNotificationsCounter(tempNotificationsCounter),
        );

        store.dispatch(
            actions[eventId].setCheckedNotifications(checkedNotifications),
        );

        store.dispatch(
          actions[eventId].setRisks(tempRisks),
        );

        store.dispatch(
          actions[eventId].setActiveRisks(tempActiveRisks),
        );


        /* we reverse the Array as want to display it correctly in the CommandLog section */
        store.dispatch(
            actions[eventId].setInitialCommandLog(matchEvents.reverse()),
        );
    } else {
        const tempAction = getObjectParamCamelCase(matchEvents, "action");

        if (tempAction) {

            const keyContract = tempAction["$type"].split(",")[0];
            const key = commandKeyExtractor(keyContract);

            matchEvents.sequenceId = lastSequenceId + 1;
            store.dispatch(actions[eventId].setSequenceId(matchEvents.sequenceId));

            if (key === "Reset") {
                store.dispatch(actions[eventId].setResetCommandLog());
                store.dispatch(actions[eventId].stopClock());
                store.dispatch(actions[eventId].resetClock());
                store.dispatch(actions[eventId].setNotificationsCounter(0));
                store.dispatch(actions[eventId].setNotifications([]));
                localStorage.removeItem('checked-notifications-' + eventId);
            }

            //hide commands which user do not want to see
            if (filteredCommands.includes(key)) {
                matchEvents.hidden = true;
            }

            store.dispatch(actions[eventId].setCommandLog(matchEvents));

            if (notificationCommands[sportStr].includes(key)) {
                const isCancelled = getObjectParamCamelCase(matchEvents, 'isCancelled');
                const isModified = getObjectParamCamelCase(matchEvents, 'isModified');
                const isNullified = getObjectParamCamelCase(tempAction, 'isNullified') ? ' Nullified' : '';
                const actionId = getObjectParamCamelCase(matchEvents, 'actionId');
                const notificationSequenceId = getObjectParamCamelCase(matchEvents, 'sequenceId');

                if (isModified && !(isCancelled || isNullified !== '')) {
                    const timeStampFull = getObjectParamCamelCase(tempAction, 'utcTimestamp');
                    const timeStamp = Moment(new Date(timeStampFull)).utc().format("HH:mm:ss");

                    let modificationTextNotification = getNotificationTextForEdit(actionId, matchEvents, key, timeStamp);

                    if (modificationTextNotification !== '') {
                        tempNotifications.unshift({actionId: actionId, sequenceId: notificationSequenceId, notificationText: modificationTextNotification, cmdObj: cloneDeep(matchEvents), type:"modified"});
                        tempNotificationsCounter++;
                    }
                }

                if (isCancelled || isNullified !== '') {
                    const timeStampFullDeleted = getObjectParamCamelCase(matchEvents, 'messageTimestampUtc');
                    const timeStampDeleted = Moment(new Date(timeStampFullDeleted)).utc().format("HH:mm:ss");

                    const deletionTextNotification = '#' + actionId + ' ' + key + ' was deleted at ' + timeStampDeleted;

                    tempNotifications.unshift({actionId: actionId, sequenceId: notificationSequenceId, notificationText: deletionTextNotification, cmdObj: cloneDeep(matchEvents), type:"deleted"});
                    tempNotificationsCounter++;
                }

                if ( key === 'Risks'){
                  tempRisks[matchEvents.actionId] = defineRisks(sportStr, tempAction, tempActiveRisks);
                  tempActiveRisks = tempRisks[matchEvents.actionId].activeRisks;
                }

                store.dispatch(
                    actions[eventId].setNotifications(tempNotifications),
                );

                store.dispatch(
                    actions[eventId].setNotificationsCounter(tempNotificationsCounter),
                );

                store.dispatch(
                  actions[eventId].setRisks(tempRisks),
                );
      
                store.dispatch(
                  actions[eventId].setActiveRisks(tempActiveRisks),
                );
            }


        }
    }
};

export const authCallbackAbly = async (type, event_id, source, sportId) => {
    const authData = await refreshAblyToken(type, event_id, source, sportId);

    if (authData && JSON.stringify(authData) !== '{}') {
        let client;
        const eventId = event_id + "_" + source;

        if (type === "liveaccess") {
            client = store.getState()[eventId].ablyClient;
        } else {
            client = store.getState()[eventId].ablyClientME;
        }

        if (client && typeof client["connection"] !== "undefined") {
            client["connection"].close();
        }

        subscribeToAbly(
            authData.accessToken,
            authData.channelName,
            type,
            event_id,
            source,
            sportId,
        );
    }
    // return { token : accessToken };
};

export const refreshAblyToken = async (type, event_id, source, sportId) => {
    const config = store.getState()["common"].config;
    const user = store.getState()["common"].user;

    const params = JSON.stringify({
        sportId: sportId,
        event_id: event_id,
        sourceId: source,
        user: user,
    });

    try {
        const token = store.getState()["common"].auth0Token;

        const feedMonitorControllerUrl =
            config["FEED_MONITOR_CONTROLLER_URL"] +
            "liveaccess" +
            (type === "matchevents" ? "/matchevents" : "");

        const response = await fetch(feedMonitorControllerUrl, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: params,
        });

        if (response.status === 200) {
            const responseTokenObject = await response.json();

            return responseTokenObject.authData;
        } else {
            return false;
        }
    } catch (e) {
        console.error(e);
    }
};

const defineRisks = (sport, tempAction, tempRisks) => {
  let activeRisks = [];
  let addedRisk = [];
  let removedRisk = [];
  sportFeatures[sport].risks.forEach(element => {
      if (getObjectParamCamelCase(tempAction, element) === 'Active'){
          activeRisks.push(element);
          if (!tempRisks.includes(element)){
              addedRisk.push(element);
          }
      } else {
          if (tempRisks.includes(element)){
              removedRisk.push(element);
          }
      }
  });

  return { addedRisk, removedRisk, activeRisks };
}

import { makeStyles } from "@material-ui/core/styles";

export const useMonitoringToolStyles = makeStyles((theme) => ({
    mt4Wrapper: {
        width: "100%",
        height: "100%",
        position: "absolute",
        maxHeight: "100%",
    },
    gridContainer: {
        width: "100%",
        height: "100%",
        position: "absolute",
        maxHeight: "100%",
        // fontFamily: '"Times New Roman", Times, serif',
        backgroundColor: "#353839",
        color: "white",
        margin: 0,
        padding: 0,
        overflow: "auto",
    },
    gridItem: {
        // height: '50%',
        // width: '25%',
        // height: window.screen.height * 0.439,
        // width: window.screen.width * 0.247,
        width: window.innerWidth / 4.037, /* itws more than 4 as there are padiding/margin between Grid Items (spacing)  */
        height: window.innerHeight / 2,
        minWidth: window.screen.width * 0.233,
        minHeight: window.screen.height * 0.43,
        // height: 473 /* TO-DO this could be changed to procentage intesead of hardcored pixels!!! */,
        // width: 475 /* TO-DO this could be changed to procentage intesead of hardcored pixels!!! */,
        //height: '50vh',
        // width: '25vw',
    //     maxWidth: window.innerWidth * 0.248,
    //     maxHeight: window.innerHeight * 0.5,
    },
}));

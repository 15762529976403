import { RootState, CommonState } from "./types";
import cloneDeep from "lodash.clonedeep";
import { splitEventsFromURL, envByUrl } from "../common/utils";
import { sportFeatures } from '../common/constants';

const selectorsObj = {};

const eventsArray = splitEventsFromURL();

eventsArray.forEach((element) => {
    const envName = envByUrl();
    const sourceIds = sportFeatures[element.sport].sourceIds[envName];

    sourceIds.forEach((source) => {
        const eventId = element.event_id + "_" + source;
        selectorsObj[eventId] = {
            selectGameTime: (state: RootState) => state[eventId].gameTime,
            selectCloseOpenGame: (state: RootState) =>
                state[eventId].currentCloseOpenGame,
            selectDisabledCloseOpenGame: (state: RootState) =>
                state[eventId].disabledCloseOpenGame,
            selectCommandLog: (state: RootState) => state[eventId].commandLog,
            selectSport: (state: RootState) => state[eventId].sport,
            selectFixtureData: (state: RootState) => state[eventId].fixtureData,
            selectMatchState: (state: RootState) => state[eventId].matchState,
            selectAblyClient: (state: RootState) => state[eventId].ablyClient,
            selectAblyClientME: (state: RootState) =>
                state[eventId].ablyClientME,
            selectSequenceId: (state: RootState) => state[eventId].sequenceId,
            selectLiveAccessForCloseGame: (state: RootState) =>
                state[eventId].liveAccessForCloseGame,
            selectMappingIds: (state: RootState) =>
                state[eventId].mappingIds,
            selectNotifications: (state: RootState) =>
                state[eventId].notifications,
            selectNotificationsCounter: (state: RootState) =>
                state[eventId].notificationsCounter,
            selectCheckedNotifications: (state: RootState) =>
                state[eventId].checkedNotifications,
            selectTabFlag: (state: RootState) =>
                state[eventId].tabFlag,
            selectRisks: (state: RootState) =>
                state[eventId].risks,
            selectActiveRisks: (state: RootState) =>
                state[eventId].activeRisks,
            selectListPosition: (state: RootState) =>
                state[eventId].listPosition,
        };
    });
});

selectorsObj["common"] = {
    selectUser: (state: CommonState) => state["common"].user,
    selectAuthorized: (state: CommonState) => state["common"].authorized,
    selectConfig: (state: CommonState) => state["common"].config,
    selectAuth0Token: (state: CommonState) => state["common"].auth0Token,
    selectOrganizationId: (state: CommonState) =>
        state["common"].organizationId,
    selectClosedWidgets: (state: CommonState) => state["common"].closedWidgets,
};

export const selectors = cloneDeep(selectorsObj);

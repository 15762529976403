import { lazy } from "react";

const AmericanFootball = lazy(
    () => import("../components/sports/americanfootball/BoxWrapper"),
);

export const sportsMapping = {
    americanfootball: { component: AmericanFootball },
};

export const allowedSport = ["americanfootball"];

/* take correct sport naming related to MSF contracts */
export const matchStateContracts = {
    americanfootball: "AmericanFootball",
};

export const sportFeatures = {
    americanfootball: {
        id: "17",
        sourceIds: {
            ci: ["GeniusSportsInPlay", "GeniusSportsInPlayTest", "GeniusPremium", "GeniusPremiumReplay", "GeniusLiveStats", "GeniusLiveStatsTest"],
            uat: ["GeniusSportsInPlay", "GeniusSportsInPlayTest", "GeniusPremium", "GeniusPremiumReplay", "GeniusLiveStats", "GeniusLiveStatsTest"],
            prod: ["GeniusSportsInPlay", "GeniusPremium", "GeniusPremiumReplay", "GeniusLiveStats", "GeniusLiveStatsTest"],
        },
        risks: ['challenge','fieldGoal','fourthDown','onsideKick','other','penalty','playAboutToStart','safety','touchdown','turnover','videoReview']
    },
};

/* Mapping to MSF contracts to take correct props */
export const commandsWithValue = {
    Reliability: { reason: [] },
    PeriodChanged: { period: ["type", "number", "progressStatus"] },
    MatchStatusChanged: { period: ["type", "number", "progressStatus"], matchStatus: [] },
    YardsToEndzone: { value: [] },
    Comment: { text : []},
    Down: { number: [], yardsToGo: [] },
    Challenge: { result: []},
    ActiveTeam: { type: []},
    CoinToss: { winnerTeam: [], homeChoice: [], awayChoice: []},
    FieldGoal: { isMade : []},
    OnePointConversion: { isMade : []},
    TwoPointsConversion: { isMade : []},
    Penalty: { type: [], yards: []},
    Punt: { yards: []},
    Fumble: { yards: []}
};

export const madeMissedCommands = ["FieldGoal", "OnePointConversion", "TwoPointsConversion"];

export const yardsContainingCommands = ["Penalty", "Punt", "Fumble"];

export const downMapping = ["1st", "2nd", "3rd", "4th"];

export const disabledCloseGameSources = ['GeniusSportsInPlayTest' , 'GeniusSportsInPlay']; /* SRT-1625 */

export const eventColors = {
    americanfootball:
        {
            CoinToss: '#d9d9d9',
            Comment: '#d9d9d9',
            YardsToEndzone: '#ffff00',
            Possession: '#42d464',
            ClockStarted: '#d9d9d9',
            ClockStopped: '#d9d9d9',
            ClockSet: '#d9d9d9',
            Timeout: '#d9d9d9',
            PeriodChanged: '#000000',
            MatchStatusChanged: '#000000',
            FieldGoal: '#16ebfa',
            Down: '#3ba8a8',
            KickOff: '#0064ab',
            Punt: '#0064ab',
            ActiveTeam : '#42d464',
            Penalty: '#fa78ca',
            Snap: '#f5cd2f',
            Sack: '#f5cd2f',
            Tackle: '#f5cd2f',
            Fumble: '#bf8f00',
            Interception: '#bf8f00',
            Touchdown: '#ff0000',
            Safety: '#ff0000',
            Single: '#ff0000',
            TwoPointsConversion: '#16ebfa',
            OnePointConversion: '#16ebfa',
            Challenge: '#fa78ca',
            Run: '#bdd7ee',
            RunAfterCatch: '#bdd7ee',
            Risks: '#9d41f2',
            CompletePass: '#bdd7ee',
            IncompletePass: '#bdd7ee',
            ConversionAttempt: '#16ebfa',
            FieldGoalAttempt: '#16ebfa',
            PassAttempt: '#bdd7ee',
            Reliability: '#f56769'
        }
    
};

export const whiteTextColors = ['#000000', '#0064ab', '#42d464', '#ff0000'];

export const allAvailableCommands = {
    //all available commands in alphabetical order
    americanfootball : [ "ActiveTeam", "Challenge", "ClockSet", "ClockStarted", "ClockStopped", "CoinToss", 
        "Comment", "CompletePass", "ConversionAttempt", "CurrentLineup", "CurrentPossession", "DeadBall", 
        "Down", "ExtraPoint", "FairCatch", "FieldGoal", "FieldGoalAttempt", "Fumble", "GameTime", 
        "IncompletePass", "Interception", "KickOff", "KickoffPlay", "Kneel", "MatchStatusChanged", 
        "Muff", "NextPlay", "OnePointConversion", "OutOfBounds", "Pass", "PassAttempt", "PatPlay", 
        "Penalty", "PeriodChanged", "PlayReview", "Possession", "Punt", "Recovery", "Reliability", "Reset", "Return", 
        "Risks", "Run", "RunAfterCatch", "Sack", "SackFumble", "Safety", "Single", "Snap", "SnapPlay", "Tackle", "Timeout", 
        "TimeoutsRemaining", "Touchback", "Touchdown", "TwoPointsConversion", "YardsToEndzone"
    ]
};

export const notificationCommands = {
    //all available commands in alphabetical order
    americanfootball : ["Challenge", "CoinToss", "Down", "FieldGoal", "Fumble", "Interception", "MatchStatusChanged", 
        "OnePointConversion", "PeriodChanged", "Safety", "Single", "Touchdown", "TwoPointsConversion"
    ]
};

export const propsToCompare = ['gameTime', 'team', 'result', 'winnerTeam', 'homeChoice', 'awayChoice', 'number', 'yardsToGo','players', 'isMade'];

//export const unreliableReasons = ['Status Unsure ', 'Call Center Lost ', 'Heartbeat Lost '];
import React from "react";
import MonitoringTool from './components/common/MonitoringTool';
// eslint-disable-next-line
import { Route, Routes } from 'react-router-dom';
import "./App.css";
// import "./fonts/RedHatText.ttf";

const App = () => {

    return (
        <div className="App" id="App" data-testid="App">
            <Routes>
                <Route path="/" element={<MonitoringTool test={false} testCount={0} />} />
            </Routes>
        </div>
    );
}


export default App;
